import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';
import AcumaticaTypeDropdownItem from './AcumaticaTypeDropdownItem';

import { v4 } from 'uuid';

const AcumaticaTypeDropdown = (props) => {
  const {
    acumaticaTypeFields,
    acumaticaTypeDropdownOpen,
    acumaticaTypeDropdownValue,
  } = useStoreState((state) => ({
    acumaticaTypeDropdownOpen: state.acumaticaTypeDropdownOpen,
    acumaticaTypeFields: state.acumaticaTypeFields,
    acumaticaTypeDropdownValue: state.acumaticaTypeDropdownValue,
  }));
  const { setAcumaticaTypeDropdownOpen } = useStoreActions((state) => ({
    setAcumaticaTypeDropdownOpen: state.setAcumaticaTypeDropdownOpen,
  }));

  const toggle = () => setAcumaticaTypeDropdownOpen(!acumaticaTypeDropdownOpen);

  return (
    <Dropdown isOpen={acumaticaTypeDropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        {acumaticaTypeDropdownValue
          ? acumaticaTypeDropdownValue
          : 'Acumatica Form Type'}
      </DropdownToggle>
      <DropdownMenu>
        {acumaticaTypeFields?.map((f) => (
          <AcumaticaTypeDropdownItem key={v4()} field={f} />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default AcumaticaTypeDropdown;
