import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import logo from '../../assets/logo.png';

const Login = (props) => {
  const { isAuthenticated, loading, user, error } = useStoreState((state) => ({
    isAuthenticated: state.isAuthenticated,
    loading: state.loading,
    user: state.user,
    error: state.error,
  }));

  //debugger;
  const { login, clearError, setUser, setAlerts, setLoading } = useStoreActions(
    (actions) => ({
      login: actions.login,
      clearError: actions.clearError,
      setUser: actions.setUser,
      setAlerts: actions.setAlerts,
      setLoading: actions.setLoading,
    })
  );
  useEffect(() => {
    if (isAuthenticated) {
      props.history.push('/');
    }
    if (
      ['Invalid Credentials.', 'Please provide email and password.'].indexOf(
        error
      ) !== -1
    ) {
      setAlerts({ error, type: 'danger' });
      clearError();
    }
    //eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  //let email = '';
  //let password = '';
  // if (user) {
  //   let email = user.email;
  //   let password = user.password;
  // }

  const { email, password } = user;

  const onChange = (e) =>
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (email === '' || password === '') {
      setAlerts({ error: 'Please fill in all fields', type: 'danger' });
    } else {
      login({ email, password });
    }
  };

  return (
    <div className="form-container">
      <div>
        <img
          // src="https://cfbs-us.com/wp-content/uploads/2015/08/logo_clients_first.jpg"
          src={logo}
          alt="WuConnect by Clients First Business Solutions"
          className=""
        />
      </div>
      <h3>
        Account <span className="text-primary">Login</span>
      </h3>
      <form onSubmit={onSubmit}>
        {/* <form> */}
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={onChange}
            required
          />
        </div>
        <input
          type="submit"
          value={!loading ? 'Login' : 'Logging in'}
          className="btn btn-primary btn-block"
          disabled={loading}
          // onClick={onSubmit}
        />
      </form>
    </div>
  );
};

export default Login;
