import React from 'react';
import PropTypes from 'prop-types';

import { useStoreActions, useStoreState } from 'easy-peasy';

const MapItem = ({ map }) => {
  const { formFields } = useStoreState((state) => ({
    formFields: state.formFields,
  }));
  const { removeMapItem, setMapDirty } = useStoreActions((actions) => ({
    removeMapItem: actions.removeMapItem,
    setMapDirty: actions.setMapDirty,
  }));

  const removeItem = (e) => {
    removeMapItem(e.target.value);
    setMapDirty(true);
  };
  let key = Object.keys(map);
  let value = map[key];

  const filteredFormFields = formFields?.filter((f) => f.Field === value);

  return (
    <div className='card-slim bg-light mt-5'>
      <div className='grid-4 m-4'>
        <div className='text-primary text-center'>
          {filteredFormFields[0]?.Title}
        </div>
        <div className='text-center description'>maps to Acumatica</div>
        <div className='text-success text-center'>{key}</div>
        <div>
          <button
            className='fa fa-trash text-danger border-0 '
            aria-hidden='true'
            onClick={removeItem}
            value={key}
          ></button>
        </div>
      </div>
    </div>
  );
};

MapItem.propTypes = {
  map: PropTypes.object.isRequired,
};

export default MapItem;
