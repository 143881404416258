import React from 'react';

const About = () => {
  return (
    <div className="jumbotron text-center">
      <h3 className="text-primary">About</h3>
      <p>
        This is an applications to set up the transaction between Wufoo forms
        and Acumitica's Leads
      </p>
      <p>Version 1.0.0</p>
    </div>
  );
};

export default About;
