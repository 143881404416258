import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';
import AcumaticaFieldsDropdownItem from './AcumaticaFieldsDropdownItem';

import { v4 } from 'uuid';

const AcumaticaFieldsDropdown = (props) => {
  const { acumaticaDropdownOpen, acumaticaFields, acumaticaDropdownValue } =
    useStoreState((state) => ({
      acumaticaDropdownOpen: state.acumaticaDropdownOpen,
      acumaticaFields: state.acumaticaFields,
      acumaticaDropdownValue: state.acumaticaDropdownValue,
    }));
  const { setAcumaticaDropdownOpen } = useStoreActions((state) => ({
    setAcumaticaDropdownOpen: state.setAcumaticaDropdownOpen,
  }));

  const toggle = () => setAcumaticaDropdownOpen(!acumaticaDropdownOpen);

  return (
    <Dropdown isOpen={acumaticaDropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        {acumaticaDropdownValue ? acumaticaDropdownValue : 'Acumatica Field'}
      </DropdownToggle>
      <DropdownMenu>
        {acumaticaFields?.map((f) => (
          <AcumaticaFieldsDropdownItem key={v4()} field={f} />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default AcumaticaFieldsDropdown;
