import React from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

const Register = () => {
  const { company, name, email, password, password2, role } = useStoreState(
    (state) => ({
      company: state.newUser.company,
      name: state.newUser.name,
      email: state.newUser.email,
      password: state.newUser.password,
      password2: state.newUser.password2,
      role: state.newUser.role,
    })
  );

  const { setNewUser, register, setAlert } = useStoreActions((actions) => ({
    setNewUser: actions.setNewUser,
    register: actions.register,
    setAlert: actions.setAlert,
  }));

  const onChange = (e) =>
    setNewUser({
      [e.target.name]: e.target.value,
    });

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === '' || email === '' || password === '') {
      setAlert('Please enter all fields', 'danger');
    } else if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      //console.log('Register submit');
      register({ company, name, email, password, role });
    }
  };

  return (
    <div className="jumbotron text-center">
      <h3 className="text-primary">Register</h3>
      <p>Register New User</p>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="company">Company</label>
          <input
            type="text"
            name="company"
            value={company}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            required
          />
        </div>
        <div className="grid-2">
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              required
              minLength="6"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password2">Confirm Password</label>
            <input
              type="password"
              name="password2"
              value={password2}
              onChange={onChange}
              required
              minLength="6"
            />
          </div>
        </div>
        <h5>User Role</h5>
        <input
          type="radio"
          name="role"
          value="user"
          checked={role === 'user'}
          onChange={onChange}
        />
        User{' '}
        <input
          type="radio"
          name="role"
          value="apiClient"
          checked={role === 'apiClient'}
          onChange={onChange}
        />
        API Client{' '}
        <input
          type="submit"
          value="Register"
          className="btn btn-primary btn-block"
        />
      </form>
    </div>
  );
};

export default Register;
