import React from 'react';
import { useStoreState } from 'easy-peasy';

const Alerts = () => {
  const { alerts } = useStoreState((state) => ({
    alerts: state.alerts,
  }));

  return (
    alerts &&
    alerts.length > 0 &&
    alerts.map((alert) => (
      <div key={alert.id} className={`alert alert-${alert.type}`}>
        <i className="fas fa-info-circle" /> {alert.error}
      </div>
    ))
  );
};

export default Alerts;
