import React from 'react';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';

const InventoryAmountInput = () => {
  const { inventoryValue } = useStoreState((state) => ({
    inventoryValue: state.inventoryValue,
  }));
  const { setInventoryValue } = useStoreActions((state) => ({
    setInventoryValue: state.setInventoryValue,
  }));

  return (
    <FormGroup row>
      <Label for='inventoryAmount' md={5}>
        Inventory Count
      </Label>
      <Col md={7}>
        <Input
          id='inventoryAmount'
          name='inventoryAmount'
          type='number'
          value={inventoryValue}
          onChange={(event) => setInventoryValue(event.target.value)}
        />
      </Col>
    </FormGroup>
  );
};

export default InventoryAmountInput;
