import React, { Fragment } from 'react';
import MapItem from './MapItem';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStoreState } from 'easy-peasy';
import { v4 } from 'uuid';

const Map = () => {
  const { mapArray } = useStoreState((state) => ({
    mapArray: state.mapArray,
  }));

  return (
    <Fragment>
      <div style={{ height: '60vh', overflow: 'auto' }}>
        <TransitionGroup>
          {mapArray.length !== 0 &&
            mapArray?.map((m) => (
              <CSSTransition key={v4()} timeout={500} classNames='item'>
                <MapItem map={m} />
              </CSSTransition>
            ))}
        </TransitionGroup>
      </div>
    </Fragment>
  );
};

export default Map;
