import React from 'react';
import Forms from '../forms/Forms';

const Home = () => {
  return (
    <div>
      <h3 className="text-primary">
        Wufoo <span className="text-success">Forms</span>
      </h3>
      <Forms />
    </div>
  );
};

export default Home;
