import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  FormGroup,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';
import WufooFieldsDropdown from './dropdowns/WufooFieldsDropdown';
import AcumaticaFieldsDropdown from './dropdowns/AcumaticaFieldsDropdown';
import AcumaticaTypeDropdown from './dropdowns/AcumaticaTypeDropdown';
import InventoryAmountInput from './dropdowns/InventoryAmountInput';
import Map from './Map';

const Configure = () => {
  const {
    showFormConfigure,
    formFields,
    loading,
    forms,
    currentFormIndex,
    wufooDropdownValue,
    acumaticaDropdownValue,
    acumaticaTypeDropdownValue,
    inventoryValue,
    mapArray,
    user,
    mapDirty,
  } = useStoreState((state) => ({
    showFormConfigure: state.showFormConfigure,
    formFields: state.formFields,
    loading: state.loading,
    forms: state.forms,
    wufooDropdownValue: state.wufooDropdownValue,
    acumaticaDropdownValue: state.acumaticaDropdownValue,
    acumaticaTypeDropdownValue: state.acumaticaTypeDropdownValue,
    inventoryValue: state.inventoryValue,
    mapArray: state.mapArray,
    user: state.user,
    currentFormIndex: state.currentFormIndex,
    mapDirty: state.mapDirty,
  }));

  const {
    setLoading,
    setShowFormConfigure,
    getWufooFormFields,
    getAcumaticaFields,
    setMapArray,
    upsertFormMap,
    cleaupConfigureModal,
    updateAcumaticaFields,
    setMapDirty,
  } = useStoreActions((state) => ({
    setLoading: state.setLoading,
    setShowFormConfigure: state.setShowFormConfigure,
    getWufooFormFields: state.getWufooFormFields,
    getAcumaticaFields: state.getAcumaticaFields,
    setMapArray: state.setMapArray,
    upsertFormMap: state.upsertFormMap,
    cleaupConfigureModal: state.cleaupConfigureModal,
    updateAcumaticaFields: state.updateAcumaticaFields,
    setMapDirty: state.setMapDirty,
  }));

  const currentHash = forms[currentFormIndex].Hash;

  useEffect(() => {
    getWufooFormFields(currentHash);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAcumaticaFields(acumaticaTypeDropdownValue.toLowerCase());
  }, [acumaticaTypeDropdownValue, getAcumaticaFields]);

  useEffect(() => {
    updateAcumaticaFields();
  }, [inventoryValue, updateAcumaticaFields]);

  const toggle = () => {
    setShowFormConfigure(false);
    cleaupConfigureModal();
  };

  const addToMap = () => {
    const mapString = `{"${acumaticaDropdownValue}": "${wufooDropdownValue}"}`;
    setMapArray(JSON.parse(mapString));
    setMapDirty(true);
  };

  const saveMap = async () => {
    setLoading(true);
    let objToSend = {};
    mapArray.forEach((m) => {
      const key = Object.keys(m)[0];
      const idx = key.indexOf('.');
      if (idx === -1) {
        const simpleObjStr = `{"${key}":"${m[key]}"}`;
        const simpleObj = JSON.parse(simpleObjStr);
        Object.assign(objToSend, simpleObj);
      } else {
        const parent = key.substring(0, idx);
        const child = key.substring(idx + 1, key.length);
        const value = m[key];
        objToSend[parent]
          ? (objToSend[parent][child] = value)
          : Object.assign(
              objToSend,
              JSON.parse(`{"${parent}":{"${child}":"${value}"}}`)
            );
      }
    });

    let mapString = JSON.stringify(objToSend);

    const payload = {
      client: user.clientGuid,
      hash: currentHash,
      fieldMap: mapString,
      webhookActive: true,
      mapType: acumaticaTypeDropdownValue,
      inventoryCount: inventoryValue,
    };

    const upsertRes = await upsertFormMap(payload);

    if (upsertRes.success) {
      cleaupConfigureModal();
      setLoading(false);
      setMapDirty(false);
    }
    toggle();
  };

  return (
    <Fragment>
      {formFields !== null && !loading && (
        <Modal
          isOpen={showFormConfigure}
          centered={true}
          size={'lg'}
          backdrop={true}
        >
          <ModalHeader>Configure Form</ModalHeader>
          <ModalBody className='text-center'>
            Select the Wufoo field and the Acumatica field to create the map for{' '}
            <span className='text-primary'>{forms[currentFormIndex].Name}</span>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup row>
                    <Col md={8}>
                      <AcumaticaTypeDropdown />
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  {acumaticaTypeDropdownValue === 'Sales' && (
                    <InventoryAmountInput />
                  )}
                </Col>
              </Row>
            </Form>
            <div className='grid-3 m-3'>
              <div>
                <WufooFieldsDropdown />
              </div>
              <div>
                <AcumaticaFieldsDropdown />
              </div>
              <button
                disabled={
                  wufooDropdownValue && acumaticaDropdownValue ? false : true
                }
                className='badge btn-warning btn-lg'
                onClick={addToMap}
              >
                Add to Map
              </button>
            </div>
            <Map />
          </ModalBody>
          <ModalFooter>
            <Button
              color='danger'
              style={{ display: 'block', width: '100%' }}
              onClick={saveMap}
              disabled={mapArray.length === 0 || mapDirty === false}
            >
              Save Map
            </Button>
            <Button
              color='light'
              style={{ display: 'block', width: '100%' }}
              onClick={toggle}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

Configure.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default Configure;
