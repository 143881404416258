import React, { useEffect, useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

const FormsFilter = () => {
  const { filteredForms } = useStoreState((state) => ({
    filteredForms: state.filteredForms,
  }));

  const { clearFormsFilter, setFilteredForms } = useStoreActions((state) => ({
    clearFormsFilter: state.clearFormsFilter,
    setFilteredForms: state.setFilteredForms,
  }));

  const text = useRef('');

  useEffect(() => {
    if (filteredForms === null) {
      text.current.value = '';
    }
  });

  const onChange = (e) => {
    if (text.current.value !== '') {
      setFilteredForms(e.target.value);
    } else {
      clearFormsFilter();
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <input
        ref={text}
        type="text"
        placeholder="Filter Forms"
        onChange={onChange}
      />
    </form>
  );
};

export default FormsFilter;
