import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, loading } = useStoreState((state) => ({
    isAuthenticated: state.isAuthenticated,
    loading: state.loading,
  }));
  const { loadUser } = useStoreActions((actions) => ({
    loadUser: actions.loadUser,
  }));

  useEffect(() => {
    if (!isAuthenticated) {
      loadUser();
    }
    // eslint-disable-line
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !loading ? (
          <Redirect to="/login" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
