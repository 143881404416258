import React, { Fragment, useEffect } from 'react';
import FormsFilter from '../forms/FormsFilter';
import FormItem from './FormItem';
import Configure from './configure/Configure';
import Spinner from '../layout/Spinner';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

const Form = () => {
  const {
    forms,
    filteredForms,
    loading,
    showFormConfigure,
    currentFormIndex,
    isAuthenticated,
  } = useStoreState((state) => ({
    forms: state.forms,
    filteredForms: state.filteredForms,
    loading: state.loading,
    showFormConfigure: state.showFormConfigure,
    currentFormIndex: state.currentFormIndex,
    isAuthenticated: state.isAuthenticated,
  }));

  const { getForms, getTypes } = useStoreActions((state) => ({
    getForms: state.getForms,
    getTypes: state.getTypes,
  }));

  useEffect(() => {
    if (isAuthenticated) {
      getForms();
      getTypes();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <FormsFilter />
      {showFormConfigure ? (
        <Configure hash={forms[currentFormIndex]?.Hash} />
      ) : (
        ''
      )}
      <div style={{ height: '90vh', overflow: 'auto' }}>
        {forms !== null && !loading ? (
          <TransitionGroup>
            {forms.length > 0 ? (
              filteredForms !== null ? (
                filteredForms.map((f, index) => (
                  <CSSTransition key={f.Hash} timeout={500} classNames='item'>
                    <FormItem form={f} index={index} />
                  </CSSTransition>
                ))
              ) : (
                forms.map((f, index) => (
                  <CSSTransition key={f.Hash} timeout={500} classNames='item'>
                    <FormItem form={f} index={index} />
                  </CSSTransition>
                ))
              )
            ) : (
              <CSSTransition
                enter={false}
                exit={false}
                key={v4()}
                timeout={500}
              >
                <div className='card'>
                  <h2>
                    We did not find any{' '}
                    <span className='text-primary'>Wufoo</span> forms
                  </h2>
                  <p className='mb-4'>
                    Have you created any{' '}
                    <span className='text-primary'>Wufoo</span> forms?
                  </p>

                  <div>
                    <p className='mb-3'>
                      If you have, here are a few items to check:
                    </p>
                    <div className='m-3'>
                      <ul className='ulVert'>
                        <li>
                          Ensure your <Link to='/client'>Client</Link>{' '}
                          configuration is complete.
                        </li>
                        <li>
                          Make sure your{' '}
                          <span className='text-primary'>Wufoo</span> Webhook
                          URL integration is set up properly.
                          <ul className='ulVert'>
                            <li>
                              Your WebHook URL should be{' '}
                              <span className='description'>
                                https://cfbsTest.selfIp.com:5000/api/v1/webhook
                              </span>
                            </li>
                            <li>
                              Make sure "Include Field and Form Structures with
                              Entry Data (?)" is checked
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            )}{' '}
          </TransitionGroup>
        ) : (
          <Spinner />
        )}
      </div>
    </Fragment>
  );
};

export default Form;
