import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useStoreActions, useStoreState } from 'easy-peasy';

const FormItem = ({ form, index }) => {
  const { Name, Description, DateCreated, Hash, WebhookActive } = form;

  const { user } = useStoreState((state) => ({
    user: state.user,
  }));

  const {
    setCurrentFormIndex,
    getFormMap,
    setLoading,
    putWebHook,
  } = useStoreActions((actions) => ({
    setCurrentFormIndex: actions.setCurrentFormIndex,
    getFormMap: actions.getFormMap,
    setLoading: actions.setLoading,
    putWebHook: actions.putWebHook,
  }));

  const { clientGuid } = user;

  const editForm = async (e) => {
    setLoading(true);
    const index = e.target.value;
    setCurrentFormIndex(index);
    await getFormMap({ Hash, client: clientGuid });
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const enableForm = async (e) => {
    setLoading(true);
    const index = e.target.value;
    setCurrentFormIndex(index);
    await putWebHook({ Hash, client: clientGuid });
  };

  return (
    <div className="card-slim bg-light mt-1">
      <div className="grid-2-btn">
        <div>
          <span
            style={{ fontSize: '10px', position: 'relative', top: '-10px' }}
          >
            Date Created: {moment(new Date(DateCreated)).format('MM/DD/YYYY')}
          </span>
          <br />
          <h6
            className="text-primary text-left"
            style={{ position: 'relative', top: '-10px', paddingLeft: '10px' }}
          >
            {Name}
          </h6>
        </div>
        <div className="mt-3 description">{Description}</div>

        {!WebhookActive ? (
          <button
            className="badge btn-success"
            value={index}
            onClick={enableForm}
          >
            Enable
          </button>
        ) : (
          <button
            className="badge btn-warning"
            value={index}
            onClick={editForm}
          >
            Configure Map
          </button>
        )}
      </div>
    </div>
  );
};

FormItem.propTypes = {
  form: PropTypes.object.isRequired,
  //formIndex: PropTypes.number.isRequired,
};

export default FormItem;
