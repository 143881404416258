const findPropsAcumatica = (obj) => {
  var result = [];
  let arName = null;
  let cnt = 0;
  function recursivelyFindProp(o) {
    Object.keys(o).forEach(function (key) {
      if (typeof o[key] === 'object' && o[key] !== 'value' && !cnt) {
        if (o[key].value === undefined) {
          arName = key;
          cnt = Object.keys(o[key]).length;
          recursivelyFindProp(o[key]);
        } else {
          let fieldObj = {};
          fieldObj.Field = key;
          result.push(fieldObj);
        }
      } else if (cnt) {
        let fieldObj = {};
        fieldObj.Field = `${arName}.${key}`;
        result.push(fieldObj);
        cnt--;
      } else {
        let fieldObj = {};
        fieldObj.Field = key;
        result.push(fieldObj);
      }
    });
  }
  recursivelyFindProp(obj);

  return result;
};

export default findPropsAcumatica;
