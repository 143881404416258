import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useStoreActions, useStoreState } from "easy-peasy";
import logo from "../../assets/logo.png";

const Navbar = ({ title, icon }) => {
  const { isAuthenticated, user } = useStoreState((state) => ({
    isAuthenticated: state.isAuthenticated,
    user: state.user,
  }));

  const { logout } = useStoreActions((actions) => ({
    logout: actions.logout,
  }));

  const onLogout = () => {
    logout();
  };

  return (
    <div className="navbar bg-primary">
      <h1 className="mt-3">
        {/* <i className={icon} /> */}
        <img src={logo} style={{ width: "200px" }} alt="CFBS WuConnect Logo" />
        {/* <span style={{ marginLeft: '20px' }}>{title}</span> */}
      </h1>
      <ul>
        {isAuthenticated && (
          <Fragment>
            <li>
              <Link to="/">Forms</Link>
            </li>
            <li>
              <Link to="/client">Client</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            {user.role === "admin" && (
              <li>
                <Link to="/register">Register</Link>
              </li>
            )}
            <li>
              <a href="#!" onClick={onLogout}>
                <i className="fas fa-sign-out-alt"></i>
                <span className="hide-sm">Logout</span>
              </a>
            </li>
          </Fragment>
        )}
      </ul>
    </div>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: "Wufoo to Acumatica",
  icon: "fa fa-exchange-alt",
};

export default Navbar;
