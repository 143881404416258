import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';
import WufooFieldsDropdownItem from './WufooFieldsDropdownItem';

import { v4 } from 'uuid';

const WufooFieldsDropdown = (props) => {
  const { wufooDropdownOpen, formFields, wufooDropdownValue } = useStoreState(
    (state) => ({
      wufooDropdownOpen: state.wufooDropdownOpen,
      formFields: state.formFields,
      wufooDropdownValue: state.wufooDropdownValue,
    })
  );
  const { setWufooDropdownOpen } = useStoreActions((state) => ({
    setWufooDropdownOpen: state.setWufooDropdownOpen,
  }));

  const toggle = () => setWufooDropdownOpen(!wufooDropdownOpen);

  return (
    <Dropdown isOpen={wufooDropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        {wufooDropdownValue ? wufooDropdownValue : 'Wufoo Field'}
      </DropdownToggle>
      <DropdownMenu>
        {formFields?.map((f) => (
          <WufooFieldsDropdownItem key={v4()} field={f} />
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default WufooFieldsDropdown;
