import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import model from './model';
import { StoreProvider, createStore } from 'easy-peasy';

import PrivateRoute from './components/routing/PrivateRoute';

import Navbar from './components/layout/Navbar';
import Alerts from './components/layout/Alerts';

import Home from './components/pages/Home';
import About from './components/pages/About';
import ClientForm from './components/client/ClientForm';
import Register from './components/pages/Register';
import Login from './components/auth/Login';

import setAuthToken from './utils/setAuthToken';
import './cfbs.css';
import './App.css';
//import 'bootstrap/dist/css/bootstrap.min.css';

const store = createStore(model);

if (localStorage.token) {
  setAuthToken(localStorage.getItem('token'));
}

const App = () => {
  return (
    <StoreProvider store={store}>
      <Router>
        <Fragment>
          <Navbar />
          <div className="container">
            <Fragment>
              <Alerts />
              <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/about" component={About} />
                <PrivateRoute exact path="/client" component={ClientForm} />
                <PrivateRoute exact path="/register" component={Register} />
                <Route exact path="/login" component={Login} />
              </Switch>
            </Fragment>
          </div>
        </Fragment>
      </Router>
    </StoreProvider>
  );
};

export default App;
