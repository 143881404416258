import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { useStoreActions } from 'easy-peasy';

const AcumaticaFieldsDropdownItem = ({ field }) => {
  const { setAcumaticaDropdownValue } = useStoreActions((state) => ({
    setAcumaticaDropdownValue: state.setAcumaticaDropdownValue,
  }));

  return (
    <Fragment>
      <DropdownItem onClick={() => setAcumaticaDropdownValue(field.Field)}>
        {field.Field}
      </DropdownItem>
    </Fragment>
  );
};
AcumaticaFieldsDropdownItem.propTypes = {
  field: PropTypes.object.isRequired,
};
export default AcumaticaFieldsDropdownItem;
