import React, { Fragment } from 'react';
import { DropdownItem } from 'reactstrap';
import { useStoreActions } from 'easy-peasy';

const AcumaticaTypeDropdownItem = ({ field }) => {
  const { setAcumaticaTypeDropdownValue } = useStoreActions((state) => ({
    setAcumaticaTypeDropdownValue: state.setAcumaticaTypeDropdownValue,
  }));

  return (
    <Fragment>
      <DropdownItem onClick={() => setAcumaticaTypeDropdownValue(field)}>
        {field}
      </DropdownItem>
    </Fragment>
  );
};

export default AcumaticaTypeDropdownItem;
