import React, { useEffect, Fragment } from 'react';
// import 'react-phone-number-input/style.css';
// import PhoneInput from 'react-phone-number-input';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Spinner from '../layout/Spinner';

const ClientForm = () => {
  const { client, clientGuid, loading } = useStoreState((state) => ({
    client: state.client,
    clientGuid: state.user.clientGuid,
    loading: state.loading,
  }));

  const {
    getClient,
    setClient,
    updateClient,
    setLoading,
    setAlerts,
    verifyACM,
    verifyWufoo,
  } = useStoreActions((state) => ({
    getClient: state.getClient,
    setClient: state.setClient,
    updateClient: state.updateClient,
    setLoading: state.setLoading,
    setAlerts: state.setAlerts,
    verifyACM: state.verifyACM,
    verifyWufoo: state.verifyWufoo,
  }));

  useEffect(() => {
    getClient(clientGuid);
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let check = true;
    if (
      client.client === '' ||
      client.baseURL === '' ||
      client.acmClientID === '' ||
      client.acmSecret === '' ||
      client.acmCompanyKey === '' ||
      client.acmUsr === '' ||
      client.acmPwd === '' ||
      client.acmEndpointName === '' ||
      client.acmEndpointVersion === ''
    ) {
      check = false;
      setAlerts({ error: 'Acumatica Settings are incomplete', type: 'danger' });
    }

    if (
      client.wufooSubdomain === '' ||
      client.wufooAPIKey === '' ||
      client.wufooHandshakeKey === ''
    ) {
      check = false;
      setAlerts({ error: 'Wufoo Settings are incomplete', type: 'danger' });
    }

    if (check) {
      client.guid = clientGuid;
      setLoading(true);
      updateClient(client);
    }
  };
  /* #region  Return  */

  const showPassword = (e) => {
    e.target.parentElement.nextElementSibling.type = 'text';
  };

  const hidePassword = (e) => {
    e.target.parentElement.nextElementSibling.type = 'password';
  };

  const verify = async (e) => {
    setLoading(true);
    e.preventDefault();
    const entity = e.target.value;
    entity === 'acumatica'
      ? await verifyACM(client)
      : await verifyWufoo(client);
  };

  return (
    <Fragment>
      {client !== null && !loading ? (
        <form onSubmit={onSubmit}>
          <h3 className='text-success'>
            <span className='text-primary'>{client.client}: </span>Acumatica{' '}
            <span className='text-dark'>and</span> Wufoo Settings
          </h3>
          <div className='card bg-light grid-2'>
            <div>
              <h4
                className='text-success text-left'
                style={{ gridColumn: 'span 2 / 3' }}
              >
                Acumatica Settings
              </h4>
            </div>
            <div>
              <button
                className={
                  client.acumaticaConfirmed ? 'btn-success' : 'btn-warning'
                }
                style={{ width: '30%', float: 'right' }}
                value='acumatica'
                onClick={verify}
                disabled={client.acumaticaConfirmed}
              >
                {client.acumaticaConfirmed ? 'Confirmed' : 'Test'}
              </button>
            </div>

            <div style={{ gridColumn: 'span 2 / 3' }}>
              <label htmlFor='baseURL'>Base URL</label>
              <input
                className='inputMin'
                type='text'
                name='baseURL'
                placeholder='Acumatica Base URL'
                value={client.baseURL}
                onChange={onChange}
              />
            </div>
            <div style={{ gridColumn: 'span 2 / 3' }}>
              <label htmlFor='acmClientID'>Connected Application ID</label>
              <input
                className='inputMin'
                type='text'
                name='acmClientID'
                placeholder='Acumatica Client ID (*not required)'
                value={client.acmClientID}
                onChange={onChange}
              />
            </div>
            <div>
              <label htmlFor='acmUser'>API User</label>
              <input
                className='inputMin'
                type='text'
                name='acmUser'
                placeholder='Acumatica API User'
                value={client.acmUser}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='acmPwd' style={{ width: '100%' }}>
                API User Password{' '}
                <i
                  className='fa fa-eye'
                  style={{ float: 'right' }}
                  onMouseDown={showPassword}
                  onMouseUp={hidePassword}
                />{' '}
              </label>
              <input
                className='inputMin'
                type='password'
                name='acmPwd'
                placeholder='Acumatica API User Password'
                value={client.acmPwd}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='acmSecret'>Connected Application Secret</label>
              <input
                className='inputMin'
                type='text'
                name='acmSecret'
                placeholder='Acumatica Client Secret (*not required)'
                value={client.acmSecret}
                onChange={onChange}
              />
            </div>
            <div>
              <label htmlFor='acmCompanyKey'>Company Key</label>
              <input
                className='inputMin'
                type='text'
                name='acmCompanyKey'
                placeholder='Acumatica Company Key'
                value={client.acmCompanyKey}
                onChange={onChange}
              />
            </div>
            <div>
              <label htmlFor='acmEndpointName'>Endpoint Name</label>
              <input
                className='inputMin'
                type='text'
                name='acmEndpointName'
                placeholder='Acumatica Endpoint Name'
                value={client.acmEndpointName}
                onChange={onChange}
              />
            </div>
            <div>
              <label htmlFor='acmEndpointVersion'>Endpoint Name</label>
              <input
                className='inputMin'
                type='text'
                name='acmEndpointVersion'
                placeholder='Acumatica Endpoint Version'
                value={client.acmEndpointVersion}
                onChange={onChange}
              />
            </div>
          </div>
          <div className='card bg-light grid-2'>
            <div>
              <h4
                className='text-success text-left'
                style={{ gridColumn: 'span 2 / 3' }}
              >
                Wufoo Settings
              </h4>
            </div>
            <div>
              <button
                className={
                  client.wufooConfirmed ? 'btn-success' : 'btn-warning'
                }
                style={{ width: '30%', float: 'right' }}
                value='wufoo'
                onClick={verify}
                disabled={client.wufooConfirmed}
              >
                {client.wufooConfirmed ? 'Confirmed' : 'Test'}
              </button>
            </div>
            <div>
              <label htmlFor='wufooSubdomain'>Wufoo Subdomain</label>
              <input
                className='inputMin'
                type='text'
                name='wufooSubdomain'
                placeholder='Wufoo Subdomain'
                value={client.wufooSubdomain}
                onChange={onChange}
              />
            </div>
            <div>
              <label htmlFor='wufooAPIKey'>Wufoo API Key</label>
              <input
                className='inputMin'
                type='text'
                name='wufooAPIKey'
                placeholder='Wufoo API Key'
                value={client.wufooAPIKey}
                onChange={onChange}
              />
            </div>

            <div>
              <label htmlFor='Wufoo Handshake Key'>Wufoo Handshake Key</label>
              <input
                className='inputMin'
                type='text'
                name='wufooHandshakeKey'
                placeholder='Wufoo Handshake Key'
                value={client.wufooHandshakeKey}
                onChange={onChange}
              />
            </div>
          </div>
          <div>
            <input
              type='submit'
              value='Update Settings'
              className='btn btn-primary btn-block'
            />
          </div>
          {/* {current && (
        <div>
          <button className="btn btn-light btn-block" onClick={clearAll}>
            Clear
          </button>
        </div>
      )} */}
        </form>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
  /* #endregion */
};

export default ClientForm;
