import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem } from 'reactstrap';
import { useStoreActions } from 'easy-peasy';

const WufooFieldsDropdownItem = ({ field }) => {
  const { setWufooDropdownValue } = useStoreActions((state) => ({
    setWufooDropdownValue: state.setWufooDropdownValue,
  }));

  return (
    <Fragment>
      <DropdownItem onClick={() => setWufooDropdownValue(field.Field)}>
        {field.Field} / {field.Title}
      </DropdownItem>
    </Fragment>
  );
};
WufooFieldsDropdownItem.propTypes = {
  field: PropTypes.object.isRequired,
};
export default WufooFieldsDropdownItem;
